﻿(function () {
    "use strict";

    angular.module('myApp').controller("ProductionCtrl", ["$scope", "$rootScope", "$state", "$localStorage", "toaster", "dashboardResource", ProductionCtrl]);

    function ProductionCtrl($scope, $rootScope, $state, $localStorage, toaster, dashboardResource) {
        var prod = this;
        prod.productionReport = {};
        $scope.todaysDate = new Date();
        prod.isDefault = false;

        $scope.goback = function () {
            window.history.back();
        };
        function formatDate(date) {
            var month = '' + (date.getMonth() + 1);
            var day = '' + date.getDate();
            var year = date.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [month, day, year].join('/');
        }

        var headerNames = [
            "Report Date",
            "Lens Design",
            "Number Of Lenses Per Design (Lab Status)",
            "Invoice Subtotal Amount (Lab Status)",
            "Number of Lenses Per Design (Mfg Mid-Point Status)",
            "Number of Lenses Per Design (Final Inspection Status)",
            "Number of Lenses Per Design (Shipping Status)",
            "Number Of Lenses Per Design (Shipped Status)",
            "Invoice Subtotal Amount (Shipped Status)",
            "Number Of Non-Conforming Lenses Per Design (Shipped Status)",
            "Total Material Price"
        ];
        // Function to get headers for Excel export
        function getHeaders() {
            return [
                { sheetid: 'Production_Report', headers: true }
            ];
        }

        // Function to calculate previous month's dates
        function calculatePreviousMonthDates(date) {
            var prevMonthLastDate = new Date(date.getFullYear(), date.getMonth(), 0);
            var prevMonthFirstDate = new Date(date.getFullYear() - (date.getMonth() > 0 ? 0 : 1), (date.getMonth() - 1 + 12) % 12, 1);
            return { dateFrom: prevMonthFirstDate, dateTo: prevMonthLastDate };
        }

        // Function to set default dates
        $scope.getDefaultDates = function () {
            $scope.currentDate = new Date();
            var dates = calculatePreviousMonthDates($scope.currentDate);
            $scope.getDates(dates.dateFrom, dates.dateTo);
        };

        // Function to get dates based on input
        $scope.getDates = function (dateFrom, dateTo) {
            prod.productionReport.dateFrom = dateFrom;
            prod.productionReport.dateTo = dateTo;
        };

        // Function to export production report to Excel
        $scope.getProdReport = function (type) {

            // Function to format date for Excel cell
            function formatExcelDate(date) {
                var month = ('0' + (date.getMonth() + 1)).slice(-2);
                var day = ('0' + date.getDate()).slice(-2);
                var year = date.getFullYear();

                return month + '/' + day + '/' + year;
            }

            // Based on the type set the isDefault value
            if (type === 'Rx') {
                prod.isDefault = true;
            } else if (type === 'Dx') {
                prod.isDefault = false;
            }

            if (prod.productionReport.dateFrom > prod.productionReport.dateTo) {
                toaster.error('From Date should be less than To Date');
                return;
            }

            var model = {
                dateFrom: prod.productionReport.dateFrom,
                dateTo: prod.productionReport.dateTo,
                offSetDateFrom: prod.productionReport.dateFrom.getTimezoneOffset(),
                offSetDateTo: prod.productionReport.dateTo.getTimezoneOffset(),
                IsRx: prod.isDefault
            };
            var currdate = new Date();
            var year = currdate.getFullYear();
            var monthlist = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var month = monthlist[currdate.getMonth()];
            var day = currdate.getDate();
            var time = currdate.getHours() + "_" + ("0" + currdate.getMinutes()).slice(-2);
            var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            dashboardResource.productionReport(model, function (res) {
                // Check if res is a string or an object
                var report;
                if (typeof res === 'string') {
                    try {
                        report = JSON.parse(res);
                    } catch (e) {
                        toaster.error('Error parsing the response!');
                        return;
                    }
                } else {
                    report = res;
                }
                // Check if report has data (assuming it's an array)
                if (report.results.length == 0) {
                    toaster.error('No record found!');
                    return;
                }
                else {
                    prod.productionReport.data = report;
                    var summary = report.results;
                    var opts = [{ sheetid: 'Production Report', headers: true }];
                    var fname = "Production Report_" + type + "_" + year + "_" + month + "_" + day + "_at_" + time + ".xlsx";

                    if (report.dataAsString) {
                        var sheetName; // Define sheetName variable here

                        var fromDateFormatted = formatExcelDate(prod.productionReport.dateFrom);
                        var toDateFormatted = formatExcelDate(prod.productionReport.dateTo);

                        // Update the sheet name based on conditions
                        if (fromDateFormatted !== toDateFormatted) {
                            sheetName = type === 'Rx' ? "Date Range Rx" : "Date Range Dx";
                        } else {
                            sheetName = type === 'Rx' ? "Daily Rx" : "Daily Dx";
                        }

                        // Add data rows to the Excel sheet
                        var workbook = new ExcelJS.Workbook();
                        var worksheet = workbook.addWorksheet(sheetName);

                        // Create the header row and apply styles
                        var headerRow = worksheet.getRow(1);
                        headerNames.forEach((header, index) => {
                            let cell = headerRow.getCell(index + 1);
                            if (header === "Report Date") {
                                cell.value = "Report Date " + summary[0].reportDate;
                            } else {
                                cell.value = header;
                            }
                            cell.font = { bold: true };
                            cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
                            worksheet.getColumn(index + 1).width = 25;
                        });
                        // Set the wrapText property for all cells in the header row
                        headerRow.eachCell({ includeEmpty: true }, function (cell) {
                            cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                        });
                        // Add data rows to the Excel sheet
                        var previousReportDate = null;
                        var isFirstIteration = true; // Flag to check if it's the first iteration

                        for (var i = 0; i < summary.length; i++) {


                            var currentReportDate = new Date(summary[i].reportDate);
                            var formattedDate = formatExcelDate(currentReportDate);

                            if (isFirstIteration || formattedDate !== previousReportDate) {
                                // Add the "Report Date" row only for the first iteration or if the date is different from the previous date
                                var dateRow = [];
                                if (fromDateFormatted !== toDateFormatted) {
                                    var dateRange = fromDateFormatted + ' - ' + toDateFormatted;
                                    dateRow.push(dateRange);
                                } else {
                                    dateRow.push(fromDateFormatted); // Display only the "from" date if "from" and "to" are the same
                                }
                                dateRow.push(""); // Empty cells for other columns in the "Report Date" row
                                dateRow.push("");
                                dateRow.push("");
                                dateRow.push("");
                                dateRow.push("");
                                dateRow.push("");
                                dateRow.push("");
                                dateRow.push("");
                                dateRow.push("");
                                dateRow.push("");
                                worksheet.addRow(dateRow).eachCell({ includeEmpty: true }, function (cell) {
                                    cell.alignment = { horizontal: 'left' };
                                });
                                previousReportDate = formattedDate; // Update the previous report date
                                isFirstIteration = false; // Update the flag after the first iteration
                            }
                            var rowData = [];
                            rowData.push("");
                            rowData.push(summary[i].lensDesign);
                            rowData.push(parseInt(summary[i].numberOfLensesPerDesignLabStatus));
                            rowData.push('$' + "    " + parseFloat(summary[i].invoiceSubtotalAmountLabStatus).toFixed(2));
                            rowData.push(parseInt(summary[i].numberOfLensesPerDesignMfgStatus));
                            rowData.push(parseInt(summary[i].numberOfLensesPerDesignFinalStatus));
                            rowData.push(parseInt(summary[i].numberOfLensesPerDesignShippingStatus));
                            rowData.push(parseInt(summary[i].numberOfLensesPerDesignShippedStatus));
                            rowData.push('$' + "    " + parseFloat(summary[i].invoiceSubtotalAmountShippedStatus).toFixed(2));
                            rowData.push(parseInt(summary[i].numberOfNonConformingLensesPerDesignShippedStatus));
                            rowData.push('$' + "    " + parseFloat(summary[i].totalMaterialPrice).toFixed(2));
                            var row = worksheet.addRow(rowData);
                            row.eachCell({ includeEmpty: true }, function (cell) {
                                cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
                            });
                        }
                        // Initialize total variables
                        var totalLabNo = 0;
                        var totalLabStatus = 0;
                        var totalMfgNo = 0;
                        var totalFinalNo = 0;
                        var totalShippingNo = 0;
                        var totalShippedNo = 0;
                        var totalShippedStatus = 0;
                        var totalNonconformNo = 0;
                        var totalMaterialPrice = 0;

                        // Loop through the results to calculate totals
                        for (var i = 0; i < summary.length; i++) {
                            var labNo = parseInt(summary[i].numberOfLensesPerDesignLabStatus) || 0;
                            var labStatusAmount = parseFloat(summary[i].invoiceSubtotalAmountLabStatus) || 0;
                            var mfgNo = parseInt(summary[i].numberOfLensesPerDesignMfgStatus) || 0;
                            var finalNo = parseInt(summary[i].numberOfLensesPerDesignFinalStatus) || 0;
                            var shippingNo = parseInt(summary[i].numberOfLensesPerDesignShippingStatus) || 0;

                            var shippedNo = parseInt(summary[i].numberOfLensesPerDesignShippedStatus) || 0;
                            var shippedStatusAmount = parseFloat(summary[i].invoiceSubtotalAmountShippedStatus) || 0;
                            var nonconformNo = parseInt(summary[i].numberOfNonConformingLensesPerDesignShippedStatus) || 0;
                            var materialPriceAmount = parseFloat(summary[i].totalMaterialPrice) || 0;

                            // Add to totals if the parsed value is a number
                            if (!isNaN(labNo)) {
                                totalLabNo += labNo;
                            }
                            if (!isNaN(labStatusAmount)) {
                                totalLabStatus += labStatusAmount;
                            }
                            if (!isNaN(mfgNo)) {
                                totalMfgNo += mfgNo;
                            }
                            if (!isNaN(finalNo)) {
                                totalFinalNo += finalNo;
                            }
                            if (!isNaN(shippingNo)) {
                                totalShippingNo += shippingNo;
                            }
                            if (!isNaN(shippedNo)) {
                                totalShippedNo += shippedNo;
                            }

                            if (!isNaN(shippedStatusAmount)) {
                                totalShippedStatus += shippedStatusAmount;
                            }
                            if (!isNaN(nonconformNo)) {
                                totalNonconformNo += nonconformNo;
                            }
                            if (!isNaN(materialPriceAmount)) {
                                totalMaterialPrice += materialPriceAmount;
                            }
                        }

                        worksheet.addRow([]);
                        // Append a new row for totals to the Excel sheet
                        worksheet.addRow(['Total:', '', totalLabNo, '$' + "    " + totalLabStatus.toFixed(2), totalMfgNo, totalFinalNo, totalShippingNo, totalShippedNo, '$' + "    " + totalShippedStatus.toFixed(2), totalNonconformNo, '$' + "    " + totalMaterialPrice.toFixed(2)]).eachCell({ includeEmpty: true }, function (cell, colNumber) {
                            cell.font = { bold: true };
                            cell.alignment = { horizontal: 'left' };
                        });

                        // Save the workbook
                        workbook.xlsx.writeBuffer().then(function (data) {
                            var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                            saveAs(blob, fname); // Use FileSaver.js or similar to save the file
                            toaster.success("Record exported.");
                        }).catch(function (error) {
                            console.error("Error exporting record:", error);
                        });
                    }
                    else {
                        alasql("SELECT INTO XLSX('" + fname + "',?) FROM ? ", [opts, [summary]]);
                        toaster.success("Record exported.");
                    }
                }
            }, function (error) {
                toaster.error(error.data.message);
            });
        };
        $scope.processSelection = function () {
            if (prod.isDefault == 0) {
                $scope.getProdReport('Rx');
                prod.isDefault = 0;
            } else if (prod.isDefault == 1) {
                $scope.getProdReport('Dx');
                prod.isDefault = 1;
            }
        }
    }
})();

